.sitio-unico-nombre{
    display: flex;
    width:235px;
    padding-left: 16px;
    padding-top: 16px;
}

.sitio-unico-tipo{
    display: flex;
    width: 221px;
    padding-top: 22px;
}

.sitio-unico-sin-tipo{
    display: flex;
    width: 221px;
}

.sitio-unico-boxes{
    display:flex;
    min-width: 450px;
    width: 680px;
    max-width: 800px;
    padding-top: 16px;
    padding-bottom: 16px;
    overflow: auto;
    overflow-y: hidden;
}

@media (max-width: 1350px) {
    .sitio-unico-nombre {
      max-width: 176px;
    }
    .sitio-unico-tipo{
        max-width: 117px;
    }
    .sitio-unico-sin-tipo{
        max-width: 117px;
    }
  }