////////////////////////////////////////NUEVOS ESTILOS////////////////////////////////////////////////////////////////////////////////
.titulo-concepto-unified-head{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e6e6e6;
    width: 200px;
    margin-right: 8px;
}
.titulo-concepto-producto-head{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e6e6e6;
    width: 350px;
    margin-right: 8px;
}

.titulo-concepto-unified{
    width: 200px;
    margin-right: 8px;
    border: 2px solid #e6e6e6;
}

.titulo-concepto-producto{
    width: 350px;
    margin-right: 8px;
    border: 2px solid #e6e6e6;
}

.titulo-dias-iram{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e6e6e6;
    width: 100px;
    margin-right: 8px;
}

.dias-iram{
    width: 100px;
    margin-right: 8px;
}

.titulo-dias-organizacion{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e6e6e6;
    width: 100px;
    margin-right: 8px;
}

.dias-organizacion{
    width: 100px;
    margin-right: 8px;
}

.titulo-moneda{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e6e6e6;
    width: 100px;
    margin-right: 8px;
}

.moneda{
    width: 100px;
    margin-right: 8px;
}

.titulo-arancel-new{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e6e6e6;
    width: 260px;
    margin-right: 8px;
}

.arancel{
    width: 260px;
    margin-right: 8px;
}

.titulo-bonificacion{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e6e6e6;
    width: 120px;
    margin-right: 8px;
}

.bonificacion{
    width: 120px;
    margin-right: 8px;
}

.titulo-recargo{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e6e6e6;
    width: 120px;
    margin-right: 8px;
}

.recargo{
    width: 120px;
    margin-right: 8px;
}

.titulo-total{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e6e6e6;
    width: 150px;
}

.total{
    width: 150px;
    justify-content: flex-end;
}

.button-habilitar{
    display:flex;
    gap:8px;    
    width:367px;
    height: 56px;
}

.concepto-realizada{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 970px;
    border: 2px solid #e6e6e6;
    border-radius: 4px;
}

////////////////PRODUCTO/////////////////////

.producto-valor{
    width: 100px;
    margin-right: 8px;
}

.producto-periodo{
    width: 46px;
    margin-right: 8px;
}

.producto-cantidad{
    width: 98px;
    margin-right: 8px;
}
.titulo-concepto-icon{
    padding-top: 8px;
    padding-left: 4px;
}

@media (max-width: 1350px) {
    .titulo-concepto-unified-head{
        min-width: 140px;
        width: 140px;
    }
    .titulo-concepto-unified{
        width: 140px;
        min-width: 140px;
        padding-left: 8px;
    }
    .titulo-dias-iram{
        width: 71px;
        min-width: 71px;
    }
    .dias-iram{
        width: 71px;
        min-width: 71px;
    }
    .titulo-dias-organizacion{
        width: 71px;
        min-width: 71px;
    }
    .dias-organizacion{
        width: 71px;
        min-width: 71px;
    }
    .titulo-moneda{
        width: 92px;
        min-width: 92px;
    }
    .moneda{
        width: 92px;
        min-width: 92px;
    }
    .titulo-arancel-new{
        width: 246px;
        min-width: 246px;
    }
    .arancel{
        width: 246px;
        min-width: 246px;
    }
    .titulo-bonificacion{
        width: 110px;
        min-width: 110px;
    }
    .bonificacion{
        width: 110px;
        min-width: 110px;
    }
    .titulo-recargo{
        width: 110px;
        min-width: 110px;
    }
    .recargo{
        width: 110px;
        min-width: 110px;
    }
    .titulo-total{
        width: 132px;
        min-width: 132px;
    }
    .total{
        width: 132px;
        min-width: 132px;
    }
    .button-habilitar{
        width:346px;
        min-width: 346px;
        height: 56px;
    }
    .concepto-realizada{
        height: 56px;
    }
    .titulo-concepto-icon{
        display: none;
    }
}