.auditoria-container{
    padding: 20px;
    padding-bottom: 100px;
    position: relative;
    margin-right: 3%;
    flex-direction: column;
    border: 1px solid #E5E5E5;
}

.auditoria-item-container{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.auditoria-item-wrapper{
    display: flex;
    justify-content: space-between;
}

.auditoria-item{
    padding: 0.5rem;
    background: #f7f7f7;
    min-width: 150px;
    height: 75px;
    border-bottom: 2px solid; 
}

.auditoria-total{
    position: absolute;
    right: 20px;
    left: 0;
    bottom: 2%;
}

.auditoria-null-item{
    padding: 2%;
    min-width: 150px;
    height: 75px;
}