.observaciones-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    width: 40vw;
    padding-top: 20px;
}

.observaciones-editor{
    height: 75vh;
}

.observaciones-titulo{
    padding-bottom: 1vh;
}