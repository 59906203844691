// Palette
$palette-primary_light:             #77cbfc !default;
$palette-primary_main:              #001D96 !default;
$palette-primary_dark:              #478fff !default;
$palette-primary_contrastText:      #fff !default;

$palette-secondary_light:           #a7a7a7 !default;
$palette-secondary_main:            #7c7c7c !default;
$palette-secondary_dark:            #424242 !default;
$palette-secondary_contrastText:    #fff !default;

$palette-text_primary:              rgba(0, 0, 0, 0.87) !default;
$palette-text_secondary:            rgba(0, 0, 0, 0.54) !default;
$palette-text_disabled:             rgba(0, 0, 0, 0.38) !default;
$palette-text_hint:                 rgba(0, 0, 0, 0.38) !default;

$palette-background_paper:          #fff !default;
$palette-background_default:        #f5f5f5 !default;

// Shape
$shape-border-radius:               8px;

// Typography
$typography-font-weight-bold:       700 !default;



// PALETA IRAM
$gris-600: #1F1F22;
$gris-500: #212529;
$gris-400: #666;
$gris-300: #9F9F9F; 
$gris-200: #E7E7E7;
$gris-100: #F6F6F6;
// PALETA IRAM FIN

// Spacing
$spacing-base:    8px !default;
$spacing-sm:      $spacing-base / 2 !default;
$spacing-md:      $spacing-base * 1.5 !default;
$spacing-lg:      $spacing-base * 2 !default;
$spacing-xl:      $spacing-base * 3 !default;

// Layout
$header-height:      64px;

$main-max-width:     1280px;