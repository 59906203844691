.sitio-muestra{
    display: flex;
    flex-direction: row;
}

.sitio-muestra-titulo{
    display: flex;
    justify-content: flex-end;
    width: 383px;
    padding-top: 10px;
}

.sitio-muestra-etapas-scroll{
    display: flex;
    overflow: auto;
    overflow: hidden;
    min-width: 450px;
    width: 680px;
    max-width: 800px;
    padding-top: 10px;
}

.sitio-muestra-box{
    display: flex;
    flex-direction: column;
    width:250px ;
    padding-left: 13px;
    padding-right: 13px;
}

.sitio-muestra-etapas{
    display: flex;
    min-width: 190px;
    width: 190px;
    max-width: 190px;
}

.sitio-muestra-sin-etapas{
    display: flex;
    flex-direction: column;    
    min-width: 190px;
    width:190px;
    max-width: 190px;
}

.sitio-muestra-sin-etapas-value{
    display: flex;
    justify-content: space-between;
}

.sitio-muestra-icon-delete{
    align-self: center;
}

@media (max-width: 1350px) {
    .sitio-muestra-titulo{
      max-width: 216px;
    }
  }