.form-select-servicios{
    width: 25vw;
}

.form-select-motivos {
    width: 60vw;
  }
  
  @media (max-width: 768px) {
    /* Adjust width for screens smaller than 768px */
    .form-select-motivos {
      width: 50vw;
    }
  }
  
  @media (max-width: 480px) {
    /* Adjust width for screens smaller than 480px */
    .form-select-motivos {
      width: 80vw;
    }
  }
  
  @media (min-width: 992px) {
    /* Adjust width for screens larger than 992px */
    .form-select-motivos {
      width: 28vw;
      max-width: 550px;
    }
  }
.input-servicios-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5vh !important;
    // padding: 24px;
}

.inputs-servicios{
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 24px;
}

.input-servicios-motivos{
    margin-left: 25px !important;
}