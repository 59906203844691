.sitio-visita-accordion{
    border: 1px solid #8a8a8a;
    border-top-left-radius: 5px 5px;
    border-top-right-radius: 5px 5px;
    border-bottom-left-radius: 5px 5px;
    border-bottom-right-radius: 5px 5px;
}

.sitio-visita-accordion{
    + .sitio-visita-accordion{
        margin-top: 10px;
    }
}

.sitio-visita-resumen-nombre{
    display: flex;
    align-items: center;
    width: 235px;
    height: 52px;
}

.sitio-visita-resumen-icon{
    display: flex;
    align-items: center;
    width: 188px;
    height: 52px;
}

.sitio-visita-resumen-box{
    display: flex;
    flex-direction: column;
    width:250px ;
    padding-left: 13px;
    padding-right: 13px;
    background-color: rgb(226, 226, 226);
    height: 52px;
    border-radius: 4px;
}

.sitio-visita-resumen-instancias{
    display:flex;
    min-width: 450px;
    width: 680px;
    max-width: 800px;
    overflow: auto;
    overflow: hidden;
}
.sitio-visita-resumen-instancia{
    width: 190px;
}

.sitio-visita-resumen-tooltip{
    display: flex;
    flex-direction: column;
}

.sitio-visita-resumen-tooltip-sitios{
    display: flex;
    justify-content: space-between;
}

.sitio-visita-resumen-tooltip-dias{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


//DETAIL ACCORDION
.sitio-visita-detail{
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    width: 100%;
}

.sitio-visita-detail-calculadora{
    display: flex;
    width: 45px;
    height: 45px;
}

.sitio-visita-detail-sitioConcreto{
    display: flex;
    flex-direction: column;
    width: 1200px;
}
.sitio-visita-detail-add{
    padding-left: 27%;
}

// .sitio-visita-detail-buttons{
//     display: flex;
//     justify-content: space-between;
//     width: 423px;
//     padding-top: 10px;
// }

// .sitio-visita-detail-calculadora{
//     align-self: center;
// }



// .sitio-visita-detail-nombre{
//     display: flex;
//     justify-content: flex-end;
//     width:200px;
// }

// .sitio-visita-detail-etapas{
//     display: flex;
//     width: 200px;
// }

// .sitio-visita-detail-input{
//     display: flex;
//     padding-left: 1%;
//     width: 200px;
// }

@media (max-width: 1350px) {
    .sitio-visita-resumen-nombre {
      max-width: 178px;
    }
    .sitio-visita-resumen-icon{
        max-width: 85px;
    }
    .sitio-visita-detail-add{
        padding-left: 18%;
    }
  }