@import "variables";
@import "mixins";
@import "common/buttons";
@import "common/motive-added";
@import "/src/scss/components/auditoria.scss";
@import "/src/scss/material/paper.scss";
@import "/src/scss/components/observaciones.scss";
@import "/src/scss/components/inputServicios.scss";
@import "/src/scss/components/paperInputServicios.scss";

html {
   position: relative;
   min-height: 100%;
}

.main {
   padding: calc(#{$header-height} + #{$spacing-xl}) $spacing-md $spacing-xl $spacing-md;
   max-width: $main-max-width;
   margin: 0 auto
}

.auth-loader-wrapper{
   position: absolute;
   display: flex;
   height: 100vh;
   width: 100vw;
   align-items: center;
   justify-content: center;
   overflow: hidden;
   top: 0;
   right: 0;
}

.loader-iram{
   
}

.pg-viewer-wrapper {
   width: 100% !important;
   height: 400px !important;
}

.pg-viewer {
   width: 100% !important;
}

.ql-toolbar{
   border-top-left-radius: 4px;
   border-top-right-radius: 4px;
}

.ql-editor{
   border-bottom-left-radius: 4px;
   border-bottom-right-radius: 4px;
}

canvas{
   width: 90% ;
}

@media (max-width: 1350px) {
   .main {
     max-width: 1100px;
   }
 }
 