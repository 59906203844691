@import "scss/variables";

.integration-detail {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   background-color: $palette-background_default;
   border-radius: $shape-border-radius;
   padding: $spacing-sm $spacing-md;
   margin: $spacing-xl 0;
   min-height: 50px;

   .norms,
   .percentage {
      font-weight: $typography-font-weight-bold;
      margin-right: $spacing-xl;
   }

   .actions {
      margin-left: auto;

      button {
         + button {
            margin-left: $spacing-md;
         }
      }
   }
}