.grupo-sitio-accordion{
    + .grupo-sitio-accordion{
        margin-top: 10px;
    }
}

.grupo-sitio-resumen-nombre{
    display: flex;
    align-items: center;
    width:230px
}

.grupo-sitio-resumen-tipo{
    display: flex;
    align-items: center;
    width: 270px;
}

.grupo-sitio-resumen-labels{
    display: flex;
}

.grupo-sitio-resumen-totales{
    width: 200px;
}

.grupo-sitio-resumen-minimos{
    width:220px;
    padding-left: 5px;
    padding-right: 5px;
}

.grupo-sitio-detail-tipo{
    width: 400px;
}

.grupo-sitio-detail-minimos{
    width:200px;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 18px;
}

@media (max-width: 1350px) {
    .grupo-sitio-resumen-nombre{
        width:180px
    }
    
    .grupo-sitio-resumen-tipo{
        width: 150px;
    }
    
    .grupo-sitio-resumen-totales{
        width: 200px;
    }
    
    .grupo-sitio-resumen-minimos{
        width:220px;
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .grupo-sitio-detail-tipo{
        width: 230px;
    }

    .grupo-sitio-detail-minimos{
        width:200px;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: 18px;
    }
  }
