.sitio-accordion{
    + .sitio-accordion{
        margin-top: 10px;
    }
}

.sitio-resumen-nombre{
    display: flex;
    width:340px
}

.sitio-resumen-normas-icon{
    display: flex;
    align-items: center;
    width: 143px;
}

.sitio-resumen-labels{
    display: flex;
}

.sitio-resumen-personal{
    display: flex;
    width: 200px;
}

.sitio-resumen-certificacion{
    display: flex;
    width:200px;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 18px;
}


@media (max-width: 1350px) {
    .sitio-resumen-nombre{
        width:216px
    }
    
    .sitio-resumen-normas-icon{
        width: 90px;
    }
    
    .sitio-resumen-personal{
        width: 215px;
    }
    
    .sitio-resumen-certificacion{
        width:200px;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: 18px;
    }
}
