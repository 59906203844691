.motive-added {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   background-color: $palette-background_default;
   border-radius: $shape-border-radius;
   padding: $spacing-sm $spacing-md;

   + .motive-added {
      margin-top: $spacing-base;
   }

   span {
      flex: 1 0 0%;
      width: 100%;
      max-width: 100%;
      
      &:last-child {
         flex: 0 0 auto;
         width: 36px;
      }
   }
}