.paper-input-servicios{
    padding: 25px;
}

.paper-input-servicios-title{
    margin-bottom: 2vh !important;
}

.paper-input-buttons-wrapper{
    display: flex;
    justify-content: space-between;
}