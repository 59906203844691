.grupo-visita-accordion{
    border: 1px solid #8a8a8a;
    border-top-left-radius: 5px 5px;
    border-top-right-radius: 5px 5px;
    border-bottom-left-radius: 5px 5px;
    border-bottom-right-radius: 5px 5px;
}

.grupo-visita-accordion{
    + .grupo-visita-accordion{
        margin-top: 10px;
    }
}

.grupo-visita-nombre{
    display: flex;
    width:235px;
}

.grupo-visita-tipo{
    display: flex;
    width: 205px;
    padding-top: 7px;
}

.grupo-visita-resumen-boxes{
    display:flex;
    min-width: 450px;
    width: 680px;
    max-width: 800px;
    overflow: auto;
    overflow-y: hidden;
}

@media (max-width: 1350px) {
    .grupo-visita-nombre {
      max-width: 160px;
    }
    .grupo-visita-tipo{
        max-width: 117px;
    }
  }