.visita-resumen-box{
    display: flex;
    flex-direction: column;
    width:250px ;
    padding-left: 13px;
    padding-right: 13px;
    border: 1px solid #8a8a8a;
    border-top-left-radius: 5px 5px;
    border-top-right-radius: 5px 5px;
    border-bottom-left-radius: 5px 5px;
    border-bottom-right-radius: 5px 5px;
}

.visita-resumen-box-title{
    padding-top: 5px;
    width: 190px;
    height: 32px;
}
.visita-resumen-box-2-label{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 190px;
}

.visita-resumen-box-label{
    padding-bottom: 5px;
    width: 190px;
}

.visita-resumen-box-label-etapas{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 190px;
}

.visita-resumen-box-certificacion-etapas{
    width:80px
}