.toolbar-actions {
   display: flex;
   justify-content: center;

   >button {
      margin: 0 $spacing-base;
   }
}

.defaultShadow {
   box-shadow: 2px 3px 11px -4px rgba(0, 0, 0, 0.5);
   -webkit-box-shadow: 2px 3px 11px -4px rgba(0, 0, 0, 0.5);
   -moz-box-shadow: 2px 3px 11px -4px rgba(0, 0, 0, 0.5);
}