@import "scss/variables";

.header {
    .toolbar {
        //@extend: calc(#{$header-height} + #{$spacing-md}) $spacing-md $spacing-md $spacing-md;
        width: 100%;
        max-width: $main-max-width;
        padding: 0 $spacing-md;
        margin: 0 auto;
    }

    .logo {
        display: flex;
        margin-right: $spacing-base;

        img {
            height: $header-height - ($spacing-sm * 2);
            padding: $spacing-sm 0;
        }
    }
}

